(function ($) {
    'use strict';
    $(document).ready(function () {
        if ("IntersectionObserver" in window) {
            let lazyImageObserver = new IntersectionObserver(function (entries) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        let lazyImage = entry.target;
                        lazyImage.src = lazyImage.dataset.src;
                        lazyImageObserver.unobserve(lazyImage);
                    }
                });
            });
            $('img[data-src]').each(function (i, lazyImage) {
                lazyImageObserver.observe(lazyImage);
            });
        } else {
            $('img[data-src]').each(function () {
                $(this).attr('src', $(this).attr('data-src'));
            });
        }
    });
})(jQuery)